import React, { Component } from 'react'
import QrReader from 'modern-react-qr-reader'
import './index.css'

class Test extends Component {
  constructor(props) {
        super(props);

        this.state = {
            result: 'No result'
        }
        this.linkFound = false;
        this.handleError = this.handleError.bind(this);
        this.handleScan = this.handleScan.bind(this);
    }

  handleScan = data => {
    if (data) {
      this.state.result = data;
        console.log(this.state.result);
        this.setState({result: data});

        if(data.includes("t.ly")){
            this.linkFound = true;
        }
    }
  }
  
  handleError = err => {
    console.error(err)
  }
  
  render() {
    return (
      <div className='main'>
        <div className='cam'>
        <QrReader
          delay={300}
          facingMode={"environment"}
          onError={this.handleError}
          onScan={this.handleScan}
        />
        </div>
        <p className='result'>{this.state.result}</p>
        {this.linkFound ? <a className='link' href={this.state.result} target="_blank" rel="noopener noreferrer">Open Link</a> : null}
      </div>
    )
  }
}

export default Test
